<template>
    <div class="h-100">
        <div class="authincation h-100"><br><br><br>
            <div class="container-fluid h-100">
                <div class="row justify-content-center h-100 align-items-center">
                    <div class="col-md-6">
                        <div class="authincation-content mb-4">
                            <div class="row no-gutters">
                                <div class="col-xl-12">
                                    <div class="auth-form">
                                        <h4 class="text-center mb-4">Admin Login</h4>
                                        <form @submit.prevent="loginUser">
                                            <div class="form-group">
                                                <input type="tel" v-model="phone" class="form-control" placeholder="phone number" required>
                                            </div>
                                            <div class="form-group">
                                                <input type="password" v-model="password" class="form-control" placeholder="Password" required>
                                            </div>
                                            <div class="text-center">
                                                <button type="submit" class="btn btn-primary btn-block">Login</button>
                                            </div>
                                            <!-- Display error message if there is one -->
                                            <p v-if="error" class="error-message">{{ error }}</p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      phone: '',
      password: '',
    };
  },
  computed: {
    ...mapState(['error', 'isAuthenticated']),
  },
  methods: {
    ...mapActions(['login']),
    async loginUser() {
      try {
        await this.login({
          phone: this.phone,
          password: this.password
        });

        if (this.isAuthenticated) {
          this.$router.push('/dashboard');
        }
      } catch (error) {
        console.error('Login failed:', error);
      }
    }
  },
};
</script>

<style>
.error-message {
  color: red;
}
</style>