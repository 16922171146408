<template>
    <div class="content-body btn-page">
         <!-- container-fluid starts -->
        <div class="container-fluid">
            <div v-if="singleUser">
                <div class="row page-titles mx-0">
                    <div class="col-sm-6 p-md-0">
                        <div class="welcome-text">
                            <h4>{{ getUserName(singleUser) }}</h4>
                        </div>
                    </div>
                </div>

                <!-- row -->
                <div class="row">

                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">User Details</h4>
                            </div>
                            <div class="card-body">
                                <div class="bootstrap-media">
                                    <div class="media">
                                        <img class="mr-3 img-fluid" width="120" :src="getUserProfileImage(singleUser)" alt="profile image">
                                        <div class="media-body">
                                            <h5 class="mt-0">{{ getUserName(singleUser) }}</h5>
                                            <ol>
                                                <li>User Type: {{ capitalizeFirstLetter(singleUser.user_type) }}</li>
                                                <li>Phone: {{ singleUser.phone }}</li>
                                                <li>Email: {{ singleUser.email || 'Not Specified' }}</li> <br>
                                                <li><a @click="showConfirmationModal" class="btn btn-danger">Delete User</a></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">{{ getUserName(singleUser) }}'s Products</h4>
                            </div>
                            <div class="card-body"  v-if="singleUser.products && singleUser.products.length > 0">
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Image</th>
                                                <th>Product Name</th>
                                                <th>Product type</th>
                                                <th>Quantity</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>                                               
                                            <tr v-for="(product) in singleUser.products" :key="product.id">
                                                <td><span>{{ product.product_id }}</span></td>
                                                <td>
                                                    <div class="round-img">
                                                        <img width="35" :src="getProductImage(product)" alt="">
                                                    </div>
                                                </td>
                                                <td><span>{{ product.name }}</span></td>
                                                <td><span>{{ capitalizeFirstLetter(product.type) }}</span></td>
                                                <td><span>{{ product.quantity }}</span></td>
                                                <td><span :class="{'badge badge-success': product.status === 'approved', 'badge badge-warning': product.status === 'pending', 'badge badge-danger': product.status === 'declined', }">{{ capitalizeFirstLetter(product.status) }}</span></td>
                                                <td>
                                                    <a :href="'/view-product/' + product.product_id" class="btn btn-primary">View</a>
                                                    <a :href="'/delete-product/' + product.product_id" class="btn btn-danger">Delete</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                                        
                            <div v-else>
                                <i>User has no product</i>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div class="row">
                    
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">{{ getUserName(singleUser) }}'s Orders</h4>
                            </div>
                            <div class="card-body" v-if="singleUser.orders && singleUser.orders.length > 0">
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Order Id</th>
                                                <th>Payment Type</th>
                                                <th>Delivery Type</th>
                                                <th>Order Items</th>
                                                <th>Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(order) in singleUser.orders" :key="order.id">
                                                <td><span>{{ order.order_id }}</span></td>
                                                <td><span>{{ capitalizeFirstLetter(order.payment_type) }}</span></td>
                                                <td><span>{{ capitalizeFirstLetter(order.delivery_type) }}</span></td>
                                                <td><span>{{ order.item_count }}</span></td>
                                                <td><span>{{ order.total_amount }}</span></td>
                                                <td><a :href="'/view-order/' + order.order_id" class="btn btn-primary">View Order</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div v-else>
                                <i>User has no orders</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <h4>No User found</h4>
            </div>
        </div>
       
    </div>

     <!-- Confirmation Modal -->
     <div class="modal fade" id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="confirmationModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="confirmationModalLabel">Confirm Deletion</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Are you sure you want to delete this user?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" @click="confirmDeletion">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { imageUrl } from '../../config';
import Swal from 'sweetalert2';

export default {
 computed: {
   ...mapState(['singleUser'])
 },
 methods: {
    fetchSingleUser() {
        // Access the user_id from the route params
        const userId = this.$route.params.user_id;

        // Now you can use user_id in your API request
        this.$store.dispatch('fetchSingleUser', userId);
    },
    capitalizeFirstLetter(str) {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    },
    getProductImage(product) {
      return `${imageUrl}/${product.image}`;
    },
    getUserProfileImage(user) {
        return user.profile_image ? `${imageUrl}/${user.profile_image}` : '/assets/images/avatar/1.png';
    },
    getUserName(user) {
        if (user.user_type === 'individual') {
        const fullName = `${user.first_name} ${user.last_name}`;
        return this.capitalizeFirstLetter(fullName);
        } else if (user.user_type === 'company') {
        return this.capitalizeFirstLetter(user.company_name);
        } else {
        return 'Unknown';
        }
    },
    showConfirmationModal() {
      // Show the confirmation modal
      // eslint-disable-next-line no-undef
      $('#confirmationModal').modal('show');
    },
    confirmDeletion() {
        console.log('Confirm deletion clicked');
        // You can add additional logic here if needed
        this.deleteUser();
    },
    async deleteUser() {
      // Get the userId from route params
      const userId = this.$route.params.user_id;

      try {
        // Make your API request to delete the user
        await this.$store.dispatch('deleteUser', userId);

        // Show success sweet alert
        Swal.fire({
          icon: 'success',
          title: 'User deleted successfully',
          showConfirmButton: false,
          timer: 1500,
        });

        // Redirect to /all-users
        this.$router.push('/all-users');
      } catch (error) {
        console.error('Failed to delete user:', error);
        // Handle the error, show an error message, etc.

        // Show error sweet alert
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete user',
          text: error.message || 'An error occurred',
        });
      } finally {
        // Close the confirmation modal (optional)
        // eslint-disable-next-line no-undef
        $('#confirmationModal').modal('hide');
      }
    },
 },
 created() {
   this.fetchSingleUser();
 },
};
</script>