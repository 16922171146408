import { createStore } from 'vuex';
import { backendApiUrl } from '../config';
import axios from 'axios';
import router from '../router';

export default createStore({
  state: {
    isAuthenticated: !!localStorage.getItem('authToken'),
    authToken: localStorage.getItem('authToken') || '',
    user: null,
    error: null,
    showNav: true,
    dashboardData: null,
    recentUsers: null,
    recentOrders: null,
    recentProducts: null,
    allUsers: null,
    allOrders: null,
    allProducts: null,
    singleProduct: null,
    singleUser: null,
    singleOrder: null,
    paginatedUsers: [],
    paginatedProducts: [],
    paginatedOrders: [],
    paginatedTransactions: [],
    totalPages: 0,
    currentPage: 1,
  },
  mutations: {
    setAuth(state, { isAuthenticated, authToken }) {
      state.isAuthenticated = isAuthenticated;
      state.authToken = authToken;
      state.error = null;
    },
    setError(state, error) {
      state.error = error;
    },
    setShowNav(state, showNav) {
      state.showNav = showNav;
    },
    setUser(state, user) {
      state.user = user;
    },
    setDashboardData(state, dashboardData) {
      state.dashboardData = dashboardData;
      state.error = null;
    },
    setRecentUsers(state, recentUsers) {
      state.recentUsers = recentUsers;
      state.error = null;
    },
    setRecentOrders(state, recentOrders) {
      state.recentOrders = recentOrders;
      state.error = null;
    },
    setRecentProducts(state, recentProducts) {
      state.recentProducts = recentProducts;
      state.error = null;
    },
    setAllUsers(state, allUsers) {
      state.allUsers = allUsers;
      state.error = null;
    },
    setPaginatedUsers(state, { users, totalCount }) {
      state.paginatedUsers = users;
      state.totalPages = Math.ceil(totalCount / 10); // Adjust based on your API response
    },
    setPaginatedProducts(state, { products, totalCount }) {
      state.paginatedProducts = products;
      state.totalPages = Math.ceil(totalCount / 10); // Adjust based on your API response
    },
    setPaginatedOrders(state, { orders, totalCount }) {
      state.paginatedOrders = orders;
      state.totalPages = Math.ceil(totalCount / 10); // Adjust based on your API response
    },
    setPaginatedTransactions(state, { transactions, totalCount }) {
      state.paginatedTransactions = transactions;
      state.totalPages = Math.ceil(totalCount / 10); // Adjust based on your API response
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setAllOrders(state, allOrders) {
      state.allOrders = allOrders;
      state.error = null;
    },
    setAllProducts(state, allProducts) {
      state.allProducts = allProducts;
      state.error = null;
    },
    setProduct(state, singleProduct) {
      state.singleProduct = singleProduct;
      state.error = null;
    },
    setSingleUser(state, singleUser) {
      state.singleUser = singleUser;
      state.error = null;
    },
    setSingleOrder(state, singleOrder) {
      state.singleOrder = singleOrder;
      state.error = null;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.authToken = '';
      state.user = null;
      state.error = null;
      // Remove the token from localStorage
      localStorage.removeItem('authToken');
    },
  },
  actions: {
    async login({ commit }, { phone, password }) {
      try {
        const response = await axios.post(`${backendApiUrl}/admin-login`, {
          phone: phone,
          password: password,
        });
        const { status, message, data } = response.data;
  
        if (status === 1000) {
          const { user, token } = data;
          commit('setAuth', { isAuthenticated: true, authToken: token });
          localStorage.setItem('authToken', token);
          commit('setUser', user);
        } else {
          commit('setError', message || 'An error occurred');
        }
      } catch (error) {
        console.error('Login failed:', error);
        commit('setError', error.message || 'An error occurred');
      }
    },
    async fetchDashboardData({ commit, state }) {
      try {
        const response = await axios.get(`${backendApiUrl}/admin/dashboard`, {
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        });
        const { status, message, data } = response.data;

        if (status === 4000) {
          commit('setDashboardData', data);
        } else {
          commit('setError', message || 'An error occurred while fetching dashboard data');
        }
      } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
        commit('setError', error.message || 'An error occurred while fetching dashboard data');
      }
    },  
    async fetchRecentUsers({ commit, state }) {
      try {
        const response = await axios.get(`${backendApiUrl}/admin/newest-users`, {
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        });

        const { status, message, data } = response.data;

        if (status === 4000) {
          commit('setRecentUsers', data);
        } else {
          commit('setError', message || 'An error occurred while fetching recent users');
        }
      } catch (error) {
        console.error('Failed to fetch recent users:', error);
        commit('setError', error.message || 'An error occurred while fetching recent users');
      }
    },
    async fetchRecentOrders({ commit, state }) {
      try {
        const response = await axios.get(`${backendApiUrl}/admin/recent-orders`, {
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        });

        const { status, message, data } = response.data;

        if (status === 4000) {
          commit('setRecentOrders', data);
        } else {
          commit('setError', message || 'An error occurred while fetching recent orders');
        }
      } catch (error) {
        console.error('Failed to fetch recent orders:', error);
        commit('setError', error.message || 'An error occurred while fetching recent orders');
      }
    },
    async fetchRecentProducts({ commit, state }) {
      try {
        const response = await axios.get(`${backendApiUrl}/admin/recent-products`, {
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        });

        const { status, message, data } = response.data;

        if (status === 4000) {
          commit('setRecentProducts', data);
        } else {
          commit('setError', message || 'An error occurred while fetching recent products');
        }
      } catch (error) {
        console.error('Failed to fetch recent products:', error);
        commit('setError', error.message || 'An error occurred while fetching recent products');
      }
    },
    async fetchAllUsers({ commit, state }, {page}) {
      try {
        const response = await axios.get(`${backendApiUrl}/admin/all-users`, {
          params: { page },
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        });

        const { status, message, data } = response.data;

        if (status === 4000) {
          commit('setPaginatedUsers', { users: data.data, totalCount: data.total });
          commit('setCurrentPage', page);
        } else {
          commit('setError', message || 'An error occurred while fetching all users');
        }
      } catch (error) {
        console.error('Failed to fetch all users:', error);
        commit('setError', error.message || 'An error occurred while fetching all users');
      }
    },
    async fetchAllOrders({ commit, state },  {page}) {
      try {
        const response = await axios.get(`${backendApiUrl}/admin/all-orders`, {
          params: { page },
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        });

        const { status, message, data } = response.data;

        if (status === 4000) {
          commit('setPaginatedOrders', { orders: data.data, totalCount: data.total });
          commit('setCurrentPage', page);
        } else {
          commit('setError', message || 'An error occurred while fetching all products');
        }
      } catch (error) {
        console.error('Failed to fetch all products:', error);
        commit('setError', error.message || 'An error occurred while fetching all products');
      }
    },
    async fetchAllProducts({ commit, state },  {page}) {
      try {
        const response = await axios.get(`${backendApiUrl}/admin/all-products`, {
          params: { page },
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        });

        const { status, message, data } = response.data;

        if (status === 4000) {
          commit('setPaginatedProducts', { products: data.data, totalCount: data.total });
          commit('setCurrentPage', page);
        } else {
          commit('setError', message || 'An error occurred while fetching all products');
        }
      } catch (error) {
        console.error('Failed to fetch all products:', error);
        commit('setError', error.message || 'An error occurred while fetching all products');
      }
    },
    async fetchSingleProduct({ commit, state }, productId) {
      try {
        const response = await axios.get(`${backendApiUrl}/admin/product/${productId}`, {
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        });

        const { status, message, data } = response.data;

        if (status === 4000) {
          commit('setProduct', data);
        } else {
          commit('setError', message || 'An error occurred while fetching product');
        }
      } catch (error) {
        console.error('Failed to fetch product:', error);
        commit('setError', error.message || 'An error occurred while fetching product');
      }
    },
    async fetchSingleUser({ commit, state }, userId) {
      try {
        const response = await axios.get(`${backendApiUrl}/admin/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        });
  
        const { status, message, data } = response.data;
        if (status === 4000) {
          commit('setSingleUser', data);  // Mutate state to store the single user data
        } else {
          commit('setError', message || 'An error occurred while fetching the user');
        }
      } catch (error) {
        console.error('Failed to fetch the user:', error);
        commit('setError', error.message || 'An error occurred while fetching the user');
      }
    },
    async fetchSingleOrder({ commit, state }, orderId) {
      try {
        const response = await axios.get(`${backendApiUrl}/admin/order/${orderId}`, {
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        });
  
        const { status, message, data } = response.data;
        if (status === 4000) {
          commit('setSingleOrder', data);  // Mutate state to store the single user data
        } else {
          commit('setError', message || 'An error occurred while fetching the order');
        }
      } catch (error) {
        console.error('Failed to fetch the order:', error);
        commit('setError', error.message || 'An error occurred while fetching the order');
      }
    },
    // eslint-disable-next-line no-unused-vars
    async deleteUser({ commit, state }, userId) {
      try {
          const response = await axios.post(
            `${backendApiUrl}/admin/user/${userId}`,
              {},
              {
                  headers: {
                      Authorization: `Bearer ${state.authToken}`,
                  },
              }
          );

          const { status, message } = response.data;

          if (status === 4000) {
            console.log('User deleted successfully');
              // Optionally, you can commit a mutation or perform any other actions
          } else {
              throw new Error(message || 'An error occurred while deleting the user');
          }
      } catch (error) {
          console.error('Failed to delete user:', error);
          throw error;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async deleteProduct({ commit, state }, productId) {
      try {
          const response = await axios.post(
            `${backendApiUrl}/admin/product/${productId}`,
              {},
              {
                  headers: {
                      Authorization: `Bearer ${state.authToken}`,
                  },
              }
          );

          const { status, message } = response.data;

          if (status === 4000) {
            console.log('Product deleted successfully');
              // Optionally, you can commit a mutation or perform any other actions
          } else {
              throw new Error(message || 'An error occurred while deleting the product');
          }
      } catch (error) {
          console.error('Failed to delete product:', error);
          throw error;
      }
    },

    // eslint-disable-next-line no-unused-vars
    async approveOrDeclineProduct({ commit, state }, { productId, action }) {
      try {
          console.log('ProductId:', productId);
          console.log('Action:', action);
  
          const response = await axios.post(
              `${backendApiUrl}/admin/product/${productId}/${action}`,
              {},
              {
                  headers: {
                      Authorization: `Bearer ${state.authToken}`,
                  },
              }
          );
  
          const { status, message } = response.data;
  
          if (status === 4000) {
              console.log('Action performed successfully');
          } else {
              throw new Error(message || 'An error occurred');
          }
      } catch (error) {
          console.error(error);
          throw error;
      }
    },

    // eslint-disable-next-line no-unused-vars
    async updateDeliveryPhase({ commit, state }, { itemId, deliveryStatus }) {
      try {
        console.log(itemId)
        console.log(deliveryStatus)
  
          const response = await axios.post(
              `${backendApiUrl}/admin/order-item/${itemId}`,
              { delivery_status: deliveryStatus },
              {
                  headers: {
                      Authorization: `Bearer ${state.authToken}`,
                      'Content-Type': 'application/json',
                  },
              }
          );
  
          const { status, message } = response.data;
  
          if (status === 4000) {
              console.log('Action performed successfully');
          } else {
              throw new Error(message || 'An error occurred');
          }
      } catch (error) {
          console.error(error);
          throw error;
      }
    },

    // eslint-disable-next-line no-unused-vars
    async markAsCompleted({ commit, state }, { orderId }) {
      try {
  
          const response = await axios.post(
              `${backendApiUrl}/admin/update-order/${orderId}`,
              {},
              {
                  headers: {
                      Authorization: `Bearer ${state.authToken}`,
                  },
              }
          );
  
          const { status, message } = response.data;
  
          if (status === 4000) {
              console.log('Action performed successfully');
          } else {
              throw new Error(message || 'An error occurred');
          }
      } catch (error) {
          console.error(error);
          throw error;
      }
    },
  
    async fetchAllTransactions({ commit, state },  {page}) {
      try {
        const response = await axios.get(`${backendApiUrl}/admin/all-transactions`, {
          params: { page },
          headers: {
            Authorization: `Bearer ${state.authToken}`,
          },
        });

        const { status, message, data } = response.data;

        if (status === 4000) {
          commit('setPaginatedTransactions', { transactions: data.data, totalCount: data.total });
          commit('setCurrentPage', page);
        } else {
          commit('setError', message || 'An error occurred while fetching all products');
        }
      } catch (error) {
        console.error('Failed to fetch all products:', error);
        commit('setError', error.message || 'An error occurred while fetching all products');
      }
    },
    logout({ commit }) {
      commit('logout');
      // Redirect to the login page
      router.push('/');
    },
  },
});
