<template>
    

    <!--**********************************
           Content body start
       ***********************************-->
       <div class="content-body">
           <!-- row -->
           <div class="container-fluid">
                <div class="row">
                    
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">All Transactions</h4>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Transaction Reference</th>
                                                <th>User</th>
                                                <th>Order ID</th>
                                                <th>Payment Status</th>
                                                <th>Payment Method</th>
                                                <th>Delivery Type</th>
                                                <th>Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(transaction) in paginatedTransactions" :key="transaction.id">
                                                <td><span>{{ transaction.transaction_reference }}</span></td>
                                                <td>{{ getUserName(transaction.user) }}</td>
                                                <td><span>{{ transaction.order_id }}</span></td>
                                                <td><span>{{ transaction.payment_status }}</span></td>
                                                <td><span>{{ capitalizeFirstLetter(transaction.payment_method) }}</span></td>
                                                <td><span>{{ capitalizeFirstLetter(transaction.delivery_type) }}</span></td>
                                                <td><span>{{ transaction.amount }}</span></td>
                                                <td>
                                                    <a :href="'/view-order/' + transaction.order_id" class="btn btn-primary">View Order</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <!-- Pagination controls -->
                                    <div class="d-flex justify-content-center mt-4">
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination">
                                            <!-- Previous page button -->
                                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                <a class="page-link" @click="changePage(currentPage - 1)" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            
                                            <!-- Render page numbers -->
                                            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                                                <a class="page-link" @click="changePage(page)">{{ page }}</a>
                                            </li>
                                            
                                            <!-- Next page button -->
                                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                                <a class="page-link" @click="changePage(currentPage + 1)" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

           </div>
       </div>
       <!--**********************************
           Content body end
       ***********************************-->
   
 
</template>

<script>
export default {
    computed: {
        paginatedTransactions() {
            return this.$store.state.paginatedTransactions
        },
        totalPages() {
            return this.$store.state.totalPages
        },
        currentPage() {
            return this.$store.state.currentPage
        },
    },
 methods: {
    changePage(page) {
      this.$store.dispatch('fetchAllTransactions', { page });
    },
   capitalizeFirstLetter(str) {
       return str.replace(/\b\w/g, (char) => char.toUpperCase());
   },
   getUserName(user) {
       if (user.user_type === 'individual') {
       const fullName = `${user.first_name} ${user.last_name}`;
       return this.capitalizeFirstLetter(fullName);
       } else if (user.user_type === 'company') {
       return this.capitalizeFirstLetter(user.company_name);
       } else {
       return 'Unknown';
       }
   },
 },
 created() {
    this.changePage(1); 
 },
};
</script>
