<template>
    <div class="content-body btn-page">
         <!-- container-fluid starts -->
        <div class="container-fluid">
            <div v-if="singleProduct">
                <div class="row page-titles mx-0">
                    <div class="col-sm-6 p-md-0">
                        <div class="welcome-text">
                            <h4>Product {{ singleProduct.product_id }}</h4>
                        </div>
                    </div>
                    <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Product</a></li>
                            <li class="breadcrumb-item active"><a href="javascript:void(0)">{{ singleProduct.product_id }}</a></li>
                        </ol>
                    </div>
                </div>

                <!-- row -->
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Product Details</h4>
                            </div>
                            <div class="card-body">
                                <div class="bootstrap-media">
                                    <div class="media">
                                        <img class="mr-3 img-fluid" width="120" :src="getProductImage(singleProduct)" alt="Quixkit">
                                        <div class="media-body">
                                            <h5 class="mt-0">{{ singleProduct.name }}</h5>
                                            <ol>
                                                <li>Brand Name: {{ singleProduct.brand_name }}</li>
                                                <li>Product Type: {{ capitalizeFirstLetter(singleProduct.type) }}</li>
                                                <li>Subcategory: {{ capitalizeFirstLetter(singleProduct.sub_category) }}</li>
                                                <li>Quantity: {{ singleProduct.quantity }}</li>
                                                <li>Price: {{ singleProduct.price }}</li>
                                                <li>Product Status: <span :class="{'badge badge-success': singleProduct.status === 'approved', 'badge badge-warning': singleProduct.status === 'pending', 'badge badge-danger': singleProduct.status === 'declined', }">{{ capitalizeFirstLetter(singleProduct.status) }}</span></li>
                                                <li>Size: {{ singleProduct.size || 'Not Specified' }}</li>
                                                <li>Weight Per Bag: {{ singleProduct.weight_per_bag || 'Not Specified' }}</li>
                                                <li>Weight Per Carton: {{ singleProduct.weight_per_carton || 'Not Specified' }}</li>
                                                <li>Weight Per Pack: {{ singleProduct.weight_per_pack || 'Not Specified' }}</li>
                                                <li>Packaging Info: {{ singleProduct.packaging_info || 'Not Specified' }}</li>
                                                <li>Breed: {{ singleProduct.breed || 'Not Specified' }}</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Product Owner Details</h4>
                            </div>
                            <div class="card-body">
                                <div class="bootstrap-media">
                                    <div class="media">
                                        <img class="mr-3 img-fluid" width="120" :src="getUserProfileImage(singleProduct.user)" alt="profile image">
                                        <div class="media-body">
                                            <h5 class="mt-0">{{ getUserName(singleProduct.user) }}</h5>
                                            <ol>
                                                <li>User Type: {{ capitalizeFirstLetter(singleProduct.user.user_type) }}</li>
                                                <li>Phone: {{ singleProduct.user.phone }}</li>
                                                <li>Email: {{ singleProduct.user.email || 'Not Specified' }}</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="card">
                                    <div class="card-body">
                                        <a v-if="singleProduct.status === 'pending' || singleProduct.status === 'declined'" 
                                        @click="performAction('approved')" 
                                        class="btn btn-success" style="color:#fff;">Approve</a>
                                        <a v-if="singleProduct.status === 'pending' || singleProduct.status === 'approved'" 
                                        @click="performAction('declined')" 
                                        class="btn btn-primary" style="color:#fff;">Decline</a>
                                        <a @click="showConfirmationModal" class="btn btn-danger" style="color:#fff;">Delete Product</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <h4>No Product found</h4>
            </div>
        </div>
       
    </div>

     <!-- Confirmation Modal -->
     <div class="modal fade" id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="confirmationModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="confirmationModalLabel">Confirm Deletion</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Are you sure you want to delete this product?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" @click="confirmDeletion">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { imageUrl } from '../../config';
import Swal from 'sweetalert2';

export default {
 computed: {
   ...mapState(['singleProduct'])
 },
 methods: {
    fetchSingleProduct() {
        // Access the product_id from the route params
        const productId = this.$route.params.product_id;

        // Now you can use productId in your API request
        this.$store.dispatch('fetchSingleProduct', productId);
    },
    capitalizeFirstLetter(str) {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    },
    getProductImage(product) {
        return `${imageUrl}/${product.image}`;
    },
    getUserProfileImage(user) {
        return user.profile_image ? `${imageUrl}/${user.profile_image}` : '/assets/images/avatar/1.png';
    },
    getUserName(user) {
        if (user.user_type === 'individual') {
        const fullName = `${user.first_name} ${user.last_name}`;
        return this.capitalizeFirstLetter(fullName);
        } else if (user.user_type === 'company') {
        return this.capitalizeFirstLetter(user.company_name);
        } else {
        return 'Unknown';
        }
    },
    showConfirmationModal() {
      // Show the confirmation modal
      // eslint-disable-next-line no-undef
      $('#confirmationModal').modal('show');
    },
    confirmDeletion() {
        console.log('Confirm deletion clicked');
        // You can add additional logic here if needed
        this.deleteProduct();
    },
    async performAction(action) {
        const productId = this.singleProduct.product_id;
        try {
            await this.$store.dispatch('approveOrDeclineProduct', { productId, action });
            // Show success message or perform any other action
            Swal.fire({
                icon: 'success',
                title: 'Product ' + action + ' successfully',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                // Introduce a delay before reloading (e.g., 1 second)
                setTimeout(() => {
                    // Reload the current page after the delay
                    location.reload();
                }, 1000);
            });
        } catch (error) {
            // Show error message or perform any other action
            Swal.fire({
                icon: 'error',
                title: 'Failed to ' + action + ' product',
                text: error.message || 'An error occurred',
            });
        }
    },

    async deleteProduct() {
      // Get the userId from route params
      const productId = this.$route.params.product_id;

      try {
        // Make your API request to delete the user
        await this.$store.dispatch('deleteProduct', productId);

        // Show success sweet alert
        Swal.fire({
          icon: 'success',
          title: 'Product deleted successfully',
          showConfirmButton: false,
          timer: 1500,
        });

        // Redirect to /all-users
        this.$router.push('/all-products');
      } catch (error) {
        console.error('Failed to delete product:', error);
        // Handle the error, show an error message, etc.

        // Show error sweet alert
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete product',
          text: error.message || 'An error occurred',
        });
      } finally {
        // Close the confirmation modal (optional)
        // eslint-disable-next-line no-undef
        $('#confirmationModal').modal('hide');
      }
    },
 },
 created() {
   this.fetchSingleProduct();
 },
};
</script>