<template>
    

    <!--**********************************
           Content body start
       ***********************************-->
       <div class="content-body">
           <!-- row -->
           <div class="container-fluid">
                <div class="row">
                    
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">All Orders</h4>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Order Id</th>
                                                <th>User</th>
                                                <th>Payment Type</th>
                                                <th>Delivery Type</th>
                                                <th>Order Items</th>
                                                <th>Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(order) in paginatedOrders" :key="order.id">
                                                <td><span>{{ order.order_id }}</span></td>
                                                <td>{{ getUserName(order.user) }}</td>
                                                <td><span>{{ capitalizeFirstLetter(order.payment_type) }}</span></td>
                                                <td><span>{{ capitalizeFirstLetter(order.delivery_type) }}</span></td>
                                                <td><span>{{ order.item_count }}</span></td>
                                                <td><span>{{ order.total_amount }}</span></td>
                                                <td>
                                                    <a :href="'/view-order/' + order.order_id" class="btn btn-primary">View Order</a>
                                                    <!-- Conditionally show the "Mark as Completed" button -->
                                                    <a v-if="order.delivery_status === 'Pending'" @click="markAsCompleted(order.order_id)" style="color:#fff;" class="btn btn-success">Mark as Completed</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <!-- Pagination controls -->
                                    <div class="d-flex justify-content-center mt-4">
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination">
                                            <!-- Previous page button -->
                                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                <a class="page-link" @click="changePage(currentPage - 1)" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            
                                            <!-- Render page numbers -->
                                            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                                                <a class="page-link" @click="changePage(page)">{{ page }}</a>
                                            </li>
                                            
                                            <!-- Next page button -->
                                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                                <a class="page-link" @click="changePage(currentPage + 1)" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

           </div>
       </div>
       <!--**********************************
           Content body end
       ***********************************-->
   
 
</template>

<script>
import Swal from 'sweetalert2';
export default {
    computed: {
        paginatedOrders() {
            return this.$store.state.paginatedOrders
        },
        totalPages() {
            return this.$store.state.totalPages
        },
        currentPage() {
            return this.$store.state.currentPage
        },
    },
 methods: {
    changePage(page) {
      this.$store.dispatch('fetchAllOrders', { page });
    },
   capitalizeFirstLetter(str) {
       return str.replace(/\b\w/g, (char) => char.toUpperCase());
   },
   getUserName(user) {
       if (user.user_type === 'individual') {
       const fullName = `${user.first_name} ${user.last_name}`;
       return this.capitalizeFirstLetter(fullName);
       } else if (user.user_type === 'company') {
       return this.capitalizeFirstLetter(user.company_name);
       } else {
       return 'Unknown';
       }
   },
   
   async markAsCompleted(orderId) {
        try {
            await this.$store.dispatch('markAsCompleted', { orderId });
            // Show success message or perform any other action
            Swal.fire({
                icon: 'success',
                title: 'Product updated successfully',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                // Introduce a delay before reloading (e.g., 1 second)
                setTimeout(() => {
                    // Reload the current page after the delay
                    location.reload();
                }, 1000);
            });
        } catch (error) {
            // Show error message or perform any other action
            Swal.fire({
                icon: 'error',
                title: 'Failed to update product',
                text: error.message || 'An error occurred',
            });
        }
    },
 },
 created() {
    this.changePage(1); 
 },
};
</script>
