import { createRouter, createWebHistory } from 'vue-router'
import notFound from '../components/notFound.vue'
import LoginComponent from '../components/Auth/LoginComponent'
import DashboardComponent from '../components/Pages/DashboardComponent'
import UsersComponent from '../components/Pages/UsersComponent'
import ProductsComponent from '../components/Pages/ProductsComponent'
import OrdersComponent from '../components/Pages/OrdersComponent'
import ViewProductComponent from '../components/Pages/ViewProductComponent'
import ViewUserComponent from '../components/Pages/ViewUserComponent'
import ViewOrderComponent from '../components/Pages/ViewOrderComponent'
import TransactionsComponent from '../components/Pages/TransactionsComponent'
import store from '../store';

const routes = [
    {
        path: '/',
        component: LoginComponent,
        meta: { showNav: false },
    },
    {
        path: '/dashboard',
        component: DashboardComponent,
        meta: { requiresAuth: true, showNav: true },
    },
    {
      path: '/all-users',
      component: UsersComponent,
      meta: { requiresAuth: true, showNav: true },
    },
    {
      path: '/view-user/:user_id',
      component: ViewUserComponent,
      meta: { requiresAuth: true, showNav: true },
    },
    {
      path: '/all-products',
      component: ProductsComponent,
      meta: { requiresAuth: true, showNav: true },
    },
    {
      path: '/all-transactions',
      component: TransactionsComponent,
      meta: { requiresAuth: true, showNav: true },
    },
    {
      path: '/view-product/:product_id',
      component: ViewProductComponent,
      meta: { requiresAuth: true, showNav: true },
    },
    {
      path: '/view-order/:order_id',
      component: ViewOrderComponent,
      meta: { requiresAuth: true, showNav: true },
    },
    {
      path: '/all-orders',
      component: OrdersComponent,
      meta: { requiresAuth: true, showNav: true },
    },
    {
        path: '/:pathMatch(.*)*',
        component: notFound,
        meta: { showNav: false },
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !isAuthenticated) {
    // If the route requires authentication and the user is not authenticated,
    // redirect to the login page
    next('/');
  } else if (to.path === '/' && isAuthenticated) {
    // If the user is already authenticated and tries to visit the login page,
    // redirect to the dashboard
    next('/dashboard');
  } else {
    // Set showNav flag based on the route's meta
    const showNav = to.meta.showNav !== undefined ? to.meta.showNav : true;
    // Commit the showNav flag to the store for global access
    store.commit('setShowNav', showNav);
    next();
    // You can set the showNav flag in a central state or use an event bus if needed
  }
});

export default router