<template>
    

    <!--**********************************
           Content body start
       ***********************************-->
       <div class="content-body btn-page">
           <!-- row -->
           <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">All Products</h4>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Image</th>
                                                <th>User</th>
                                                <th>Product Name</th>
                                                <th>Product type</th>
                                                <th>Quantity</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(product) in paginatedProducts" :key="product.id">
                                                <td><span>{{ product.product_id }}</span></td>
                                                <td>
                                                    <div class="round-img">
                                                        <img width="35" :src="getProductImage(product)" alt="">
                                                    </div>
                                                </td>
                                                <td>{{ getUserName(product.user) }}</td>
                                                <td><span>{{ product.name }}</span></td>
                                                <td><span>{{ capitalizeFirstLetter(product.type) }}</span></td>
                                                <td><span>{{ product.quantity }}</span></td>
                                                <td><span :class="{'badge badge-success': product.status === 'approved', 'badge badge-warning': product.status === 'pending', 'badge badge-danger': product.status === 'declined', }">{{ capitalizeFirstLetter(product.status) }}</span></td>
                                                <td>
                                                    <a :href="'/view-product/' + product.product_id" class="btn btn-primary">View</a>
                                                    <a @click="showConfirmationModal(product)" class="btn btn-danger">Delete</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- Pagination controls -->
                                    <div class="d-flex justify-content-center mt-4">
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination">
                                            <!-- Previous page button -->
                                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                <a class="page-link" @click="changePage(currentPage - 1)" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            
                                            <!-- Render page numbers -->
                                            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                                                <a class="page-link" @click="changePage(page)">{{ page }}</a>
                                            </li>
                                            
                                            <!-- Next page button -->
                                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                                <a class="page-link" @click="changePage(currentPage + 1)" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

           </div>
       </div>
       <!--**********************************
           Content body end
       ***********************************-->
   
 
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { imageUrl } from '../../config';
import Swal from 'sweetalert2';

export default {
 computed: {
    paginatedProducts() {
      return this.$store.state.paginatedProducts
    },
    totalPages() {
      return this.$store.state.totalPages
    },
    currentPage() {
      return this.$store.state.currentPage
    },
 },
 methods: {
    changePage(page) {
      this.$store.dispatch('fetchAllProducts', { page });
    },
   capitalizeFirstLetter(str) {
       return str.replace(/\b\w/g, (char) => char.toUpperCase());
   },
   getProductImage(product) {
      return `${imageUrl}/${product.image}`;
    },
   getUserName(user) {
       if (user.user_type === 'individual') {
       const fullName = `${user.first_name} ${user.last_name}`;
       return this.capitalizeFirstLetter(fullName);
       } else if (user.user_type === 'company') {
       return this.capitalizeFirstLetter(user.company_name);
       } else {
       return 'Unknown';
       }
   },
   showConfirmationModal(product) {
         // Show the confirmation modal
         Swal.fire({
            title: 'Confirm Deletion',
            text: `Are you sure you want to delete ${product.name}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
         }).then((result) => {
            if (result.isConfirmed) {
               // Call the delete method if the user confirms
               this.deleteProduct(product.product_id);
            }
         });
    },
    async deleteProduct(productId) {

      try {
        // Make your API request to delete the user
        await this.$store.dispatch('deleteProduct', productId);

        // Show success sweet alert
        await Swal.fire({
          icon: 'success',
          title: 'Product deleted successfully',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
            // Introduce a delay before reloading (e.g., 1 second)
            setTimeout(() => {
                // Reload the current page after the delay
                location.reload();
            }, 1000);
        });
      } catch (error) {
        console.error('Failed to delete user:', error);
        // Handle the error, show an error message, etc.

        // Show error sweet alert
        Swal.fire({
          icon: 'error',
          title: 'Failed to delete product',
          text: error.message || 'An error occurred',
        });
      } finally {
        // Close the confirmation modal (optional)
        // eslint-disable-next-line no-undef
        $('#confirmationModal').modal('hide');
      }
    },
 },
 created() {
    this.changePage(1); 
 },
};
</script>
