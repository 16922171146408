<!-- LogoutButton.vue -->

<template>
    <a @click="logout" href="#" class="dropdown-item">
        <i class="icon-key"></i>
        <span class="ml-2">Logout </span>
    </a>
</template>
  
<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['logout']),
  },
};
</script>
  