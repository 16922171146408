<template>
    

     <!--**********************************
            Content body start
        ***********************************-->
        <div class="content-body">
            <!-- row -->
            <div class="container-fluid">
                <div>
                    <div v-if="dashboardData">
                        <div class="row">
                            <div v-for="widget in dashboardWidgets" :key="widget.id" class="col-lg-3 col-sm-6">
                                <div class="card">
                                    <div class="stat-widget-two card-body">
                                        <div class="stat-content">
                                            <div class="stat-text">{{ widget.title }}</div>
                                            <div class="stat-digit">{{ widget.value }}</div>
                                        </div>
                                        <div class="progress">
                                            <div :class="widget.progressBarClass" :style="widget.progressBarWidth" role="progressbar"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                    Loading dashboard data...
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">New Users</h4>
                                <div class="ml-auto">
                                    <a href="/all-users" class="text-primary">All Users</a>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>User type</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(user) in recentUsers" :key="user.id">
                                                <td>
                                                    <div class="round-img">
                                                        <img width="35" :src="getUserProfileImage(user)" alt="">
                                                    </div>
                                                </td>
                                                <td>{{ getUserName(user) }}</td>
                                                <td><span>{{ user.phone }}</span></td>
                                                <td><span>{{ user.user_type }}</span></td>
                                                <td><span class="badge badge-success">Active</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-4">
                        <div class="card">
                            <div class="card-body text-center">
                                <div class="m-t-10">
                                    <h4 class="card-title">Customer Feedback</h4>
                                    <h2 class="mt-3">385749</h2>
                                </div>
                                <ul class="widget-line-list m-b-15">
                                    <li class="border-right">92% <br><span class="text-success"><i
                                                class="ti-hand-point-up"></i> Positive</span></li>
                                    <li>8% <br><span class="text-danger"><i
                                                class="ti-hand-point-down"></i>Negative</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">New Orders</h4>
                                <div class="ml-auto">
                                    <a href="/all-orders" class="text-primary">All Orders</a>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Order Id</th>
                                                <th>User</th>
                                                <th>Payment Type</th>
                                                <th>Delivery Type</th>
                                                <th>Order Items</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(order) in recentOrders" :key="order.id">
                                                <td><span>{{ order.order_id }}</span></td>
                                                <td>{{ getUserName(order.user) }}</td>
                                                <td><span>{{ capitalizeFirstLetter(order.payment_type) }}</span></td>
                                                <td><span>{{ capitalizeFirstLetter(order.delivery_type) }}</span></td>
                                                <td><span>{{ order.item_count }}</span></td>
                                                <td><span>{{ order.total_amount }}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">New Products</h4>
                                <div class="ml-auto">
                                    <a href="/all-products" class="text-primary">All Products</a>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Image</th>
                                                <th>User</th>
                                                <th>Product Name</th>
                                                <th>Product type</th>
                                                <th>Quantity</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(product) in recentProducts" :key="product.id">
                                                <td><span>{{ product.product_id }}</span></td>
                                                <td>
                                                    <div class="round-img">
                                                        <img width="35" :src="getProductImage(product)" alt="">
                                                    </div>
                                                </td>
                                                <td>{{ getUserName(product.user) }}</td>
                                                <td><span>{{ product.name }}</span></td>
                                                <td><span>{{ product.type }}</span></td>
                                                <td><span>{{ product.quantity }}</span></td>
                                                <td><span :class="{'badge badge-success': product.status === 'approved', 'badge badge-warning': product.status === 'pending', 'badge badge-danger': product.status === 'declined', }">{{ capitalizeFirstLetter(product.status) }}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--**********************************
            Content body end
        ***********************************-->
    
  
</template>

<script>
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { imageUrl } from '../../config';

export default {
  computed: {
    ...mapState(['dashboardData', 'recentUsers', 'recentOrders', 'recentProducts']),
    dashboardWidgets() {
      if (!this.dashboardData) {
        return []; 
      }

      return [
        {
          id: 1,
          title: 'Total Users',
          value: this.dashboardData.totalUsers,
          progressBarClass: 'progress-bar-success',
          progressBarWidth: 'width: ' + 70 + '%',
        },
        {
          id: 2,
          title: 'Total Orders',
          value: this.dashboardData.totalOrders,
          progressBarClass: 'progress-bar-primary',
          progressBarWidth: 'width: ' + 45 + '%',
        },
        {
          id: 3,
          title: 'Total Products',
          value: this.dashboardData.totalSalesProducts,
          progressBarClass: 'progress-bar-warning',
          progressBarWidth: 'width: ' + 65 + '%',
        },
        {
          id: 4,
          title: 'Total Income',
          value: this.dashboardData.totalAmountInOrders,
          progressBarClass: 'progress-bar-danger',
          progressBarWidth: 'width: ' + 85 + '%',
        },
      ];
    },
  },
  methods: {
    ...mapActions(['fetchDashboardData', 'fetchRecentUsers', 'fetchRecentOrders', 'fetchRecentProducts']),
    capitalizeFirstLetter(str) {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    },
    getUserProfileImage(user) {
      return user.profile_image ? `${imageUrl}/${user.profile_image}` : 'assets/images/avatar/1.png';
    },
    getProductImage(product) {
      return `${imageUrl}/${product.image}`;
    },
    getUserName(user) {
        if (user.user_type === 'individual') {
        const fullName = `${user.first_name} ${user.last_name}`;
        return this.capitalizeFirstLetter(fullName);
        } else if (user.user_type === 'company') {
        return this.capitalizeFirstLetter(user.company_name);
        } else {
        return 'Unknown'; // Handle other user types as needed
        }
    },
  },
  created() {
    // Fetch dashboard data when the component is created
    this.fetchDashboardData();
    this.fetchRecentUsers();
    this.fetchRecentOrders();
    this.fetchRecentProducts();
  },
};
</script>
