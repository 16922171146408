<template>
    <div class="content-body btn-page">
         <!-- container-fluid starts -->
        <div class="container-fluid">
            <div v-if="singleOrder">
                <div class="row page-titles mx-0">
                    <div class="col-sm-6 p-md-0">
                        <div class="welcome-text">
                            <h4>Order {{ singleOrder.order_id }}</h4>
                        </div>
                    </div>
                    <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Order</a></li>
                            <li class="breadcrumb-item active"><a href="javascript:void(0)">{{ singleOrder.order_id }}</a></li>
                        </ol>
                    </div>
                </div>

                <!-- row -->
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Order Details</h4>
                            </div>
                            <div class="card-body">
                                <div class="bootstrap-media">
                                    <div class="media">
                                        <div class="media-body">
                                            <ol>
                                                <li>Payment Type: {{ capitalizeFirstLetter(singleOrder.payment_type) }}</li>
                                                <li>Mode of Delivey: {{ capitalizeFirstLetter(singleOrder.delivery_type) }}</li>
                                                <li>Total Amount: {{ singleOrder.total_amount }}</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-header">
                                <h4 class="card-title">Drop-off Point</h4>
                            </div>
                            <div class="card-body">
                                <div class="bootstrap-media">
                                    <div class="media">
                                        <div class="media-body">
                                            <ol>
                                                <li>Address: {{ singleOrder.shipping_info.address }}, </li>
                                                <li>Landmark: {{ singleOrder.shipping_info.landmark }}</li>
                                                <li>Region: {{ singleOrder.shipping_info.region }}</li>
                                                <li>City: {{ singleOrder.shipping_info.city }}</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Buyer Details</h4>
                            </div>
                            <div class="card-body">
                                <div class="bootstrap-media">
                                    <div class="media">
                                        <img class="mr-3 img-fluid" width="120" :src="getUserProfileImage(singleOrder.user)" alt="profile image">
                                        <div class="media-body">
                                            <h5 class="mt-0">{{ getUserName(singleOrder.user) }}</h5>
                                            <ol>
                                                <li>User Type: {{ capitalizeFirstLetter(singleOrder.user.user_type) }}</li>
                                                <li>Phone: {{ singleOrder.user.phone }}</li>
                                                <li>Email: {{ singleOrder.user.email || 'Not Specified' }}</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h3 class="text-center">Order Items</h3>
                <!-- row -->
                <div class="row">
                    <div class="col-lg-6" v-for="(order_item) in singleOrder.order_items" :key="order_item.id">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">{{ order_item.order_id }}/{{ order_item.item_position }}</h4>
                            </div>
                            <div class="card-body">
                                <div class="bootstrap-media">
                                    <img class="mr-3 img-fluid" width="120" :src="getProductImage(order_item.product)" alt="Quixkit">
                                    <div class="media">
                                        <div class="media-body">
                                            <ol>
                                                <li>Quantity: {{ order_item.quantity }}</li>
                                                <li v-if="order_item.delivery_stage == 1">Delivery stage: Order placed</li>
                                                <li v-else-if="order_item.delivery_stage == 2">Delivery stage: Order confirmed</li>
                                                <li v-else-if="order_item.delivery_stage == 3">Delivery stage: Pickup point</li>
                                                <li v-else-if="order_item.delivery_stage == 4">Delivery stage: Shipped</li>
                                                <li v-else>Delivery stage: Unknown</li>
                                                <li>Pick-Up Point: 
                                                    {{ order_item.pickup_station.address }}, 
                                                    {{ order_item.pickup_station.landmark }},
                                                    {{ order_item.pickup_station.region }},
                                                    {{ order_item.pickup_station.city }}
                                                </li>
                                                <li>Price: {{ order_item.price }}</li>
                                                <li>Brand Name: {{ order_item.product.brand_name || 'Not Specified' }}</li>
                                                <li>Item Name: {{ order_item.product.name }}</li>
                                                <li>Product Type: {{ capitalizeFirstLetter(order_item.product.type) }}</li>
                                                <li>Size: {{ order_item.product.size || 'Not Specified' }}</li>
                                                <li>Weight Per Bag: {{ order_item.product.weight_per_bag || 'Not Specified' }}</li>
                                                <li>Weight Per Carton: {{ order_item.product.weight_per_carton || 'Not Specified' }}</li>
                                                <li>Weight Per Pack: {{ order_item.product.weight_per_pack || 'Not Specified' }}</li>
                                                <li>Packaging Info: {{ order_item.product.packaging_info || 'Not Specified' }}</li>
                                                <li>Breed: {{ order_item.product.breed || 'Not Specified' }}</li>
                                            </ol>
                                            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#basicModal">Update Delivery Stage</button>
                                             <!-- Modal -->
                                            <div class="modal fade" :class="{ 'show': showModal }" id="basicModal" tabindex="-1" role="dialog" aria-hidden="true">
                                                <div class="modal-dialog" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title">Delivery phase</h5>
                                                            <button type="button" class="close" data-dismiss="modal"><span>&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <form @submit.prevent="updateDeliveryPhaseAction(order_item.id)">
                                                                <div class="form-group">
                                                                    <label for="deliveryPhase">Delivery Phase:</label>
                                                                    <select v-model="selectedDeliveryPhase" class="form-control" id="deliveryPhase">
                                                                        <option v-for="(phase, index) in deliveryPhases" :key="index" :value="index + 1">{{ phase }}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="submit" class="btn btn-primary">Save changes</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div v-else>
                <h4>No Order found</h4>
            </div>
        </div>
       
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { imageUrl } from '../../config';
import Swal from 'sweetalert2';

export default {
 computed: {
   ...mapState(['singleOrder'])
 },
 data() {
    return {
        selectedDeliveryPhase: null,
        deliveryPhases: [
            'Order placed',
            'Order confirmed',
            'Pickup point',
            'Shipped'
        ]
    };
 },
 methods: {
    ...mapActions(['updateDeliveryPhase']),
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    fetchSingleOrder() {
        // Access the product_id from the route params
        const orderId = this.$route.params.order_id;

        // Now you can use productId in your API request
        this.$store.dispatch('fetchSingleOrder', orderId)
    },
    capitalizeFirstLetter(str) {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    },
    getProductImage(product) {
        return `${imageUrl}/${product.image}`;
    },
    getUserProfileImage(user) {
        return user.profile_image ? `${imageUrl}/${user.profile_image}` : '/assets/images/avatar/1.png';
    },
    getUserName(user) {
        if (user.user_type === 'individual') {
        const fullName = `${user.first_name} ${user.last_name}`;
        return this.capitalizeFirstLetter(fullName);
        } else if (user.user_type === 'company') {
        return this.capitalizeFirstLetter(user.company_name);
        } else {
        return 'Unknown';
        }
    },
    async updateDeliveryPhaseAction(itemId) {
        if (!itemId || !this.selectedDeliveryPhase) {
            return; 
        }

        try {
            const message = await this.updateDeliveryPhase({
                itemId: itemId,
                deliveryStatus: this.selectedDeliveryPhase
            });
            // Display success message using Swal
            Swal.fire({
                icon: 'success',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then(() => {
                // Introduce a delay before reloading (e.g., 1 second)
                setTimeout(() => {
                    // Reload the current page after the delay
                    location.reload();
                }, 1000);
            });
            // Close the modal after updating
            this.closeModal();
        } catch (error) {
            // Display error message using Swal
            Swal.fire({
                icon: 'error',
                title: 'Failed to update delivery phase',
                text: error.message || 'An error occurred',
            });
        }
    },
 },
 created() {
   this.fetchSingleOrder();
 },
};
</script>