<template>
         <!--**********************************
               Nav header start
           ***********************************-->
           <div v-if="showNav" class="nav-header">
               <a href="/" class="brand-logo">
                  Fishdey Admin
               </a>
           </div>
           <!--**********************************
               Nav header end
           ***********************************-->
   
           <!--**********************************
               Header start
           ***********************************-->
           <div  v-if="showNav" class="header">
               <div class="header-content">
                   <nav class="navbar navbar-expand">
                       <div class="collapse navbar-collapse justify-content-between">
                           <div class="header-left">
                               
                           </div>
   
                           <ul class="navbar-nav header-right">
                               <!-- <li class="nav-item dropdown notification_dropdown">
                                   <a class="nav-link" href="#" role="button" data-toggle="dropdown">
                                       <i class="mdi mdi-bell"></i>
                                       <div class="pulse-css"></div>
                                   </a>
                                   <div class="dropdown-menu dropdown-menu-right">
                                       <ul class="list-unstyled">
                                           <li class="media dropdown-item">
                                               <span class="success"><i class="ti-user"></i></span>
                                               <div class="media-body">
                                                   <a href="#">
                                                       <p><strong>Martin</strong> has added a <strong>customer</strong> Successfully
                                                       </p>
                                                   </a>
                                               </div>
                                               <span class="notify-time">3:20 am</span>
                                           </li>
                                           <li class="media dropdown-item">
                                               <span class="primary"><i class="ti-shopping-cart"></i></span>
                                               <div class="media-body">
                                                   <a href="#">
                                                       <p><strong>Jennifer</strong> purchased Light Dashboard 2.0.</p>
                                                   </a>
                                               </div>
                                               <span class="notify-time">3:20 am</span>
                                           </li>
                                           <li class="media dropdown-item">
                                               <span class="danger"><i class="ti-bookmark"></i></span>
                                               <div class="media-body">
                                                   <a href="#">
                                                       <p><strong>Robin</strong> marked a <strong>ticket</strong> as unsolved.
                                                       </p>
                                                   </a>
                                               </div>
                                               <span class="notify-time">3:20 am</span>
                                           </li>
                                           <li class="media dropdown-item">
                                               <span class="primary"><i class="ti-heart"></i></span>
                                               <div class="media-body">
                                                   <a href="#">
                                                       <p><strong>David</strong> purchased Light Dashboard 1.0.</p>
                                                   </a>
                                               </div>
                                               <span class="notify-time">3:20 am</span>
                                           </li>
                                           <li class="media dropdown-item">
                                               <span class="success"><i class="ti-image"></i></span>
                                               <div class="media-body">
                                                   <a href="#">
                                                       <p><strong> James.</strong> has added a<strong>customer</strong> Successfully
                                                       </p>
                                                   </a>
                                               </div>
                                               <span class="notify-time">3:20 am</span>
                                           </li>
                                       </ul>
                                       <a class="all-notification" href="#">See all notifications <i
                                               class="ti-arrow-right"></i></a>
                                   </div>
                               </li> -->
                               <li class="nav-item dropdown header-profile">
                                   <a class="nav-link" href="#" role="button" data-toggle="dropdown">
                                       <i class="mdi mdi-account"></i>
                                   </a>
                                   <div class="dropdown-menu dropdown-menu-right">
                                       <a href="/profile" class="dropdown-item">
                                           <i class="icon-user"></i>
                                           <span class="ml-2">Profile </span>
                                       </a>
                                       <!-- <a href="./email-inbox.html" class="dropdown-item">
                                           <i class="icon-envelope-open"></i>
                                           <span class="ml-2">Inbox </span>
                                       </a> -->
                                       <logout-button></logout-button>
                                   </div>
                               </li>
                           </ul>
                       </div>
                   </nav>
               </div>
           </div>
           <!--**********************************
               Header end ti-comment-alt
           ***********************************-->
   
           <!--**********************************
               Sidebar start
           ***********************************-->
           <div  v-if="showNav" class="quixnav">
               <div class="quixnav-scroll">
                   <ul class="metismenu" id="menu">
                       <li><a class="nav-link" href="/dashboard" aria-expanded="false"><i
                                   class="icon icon-single-04"></i><span class="nav-text">Dashboard</span></a>
                       </li>
                       <li><a class="nav-link" href="/all-users" aria-expanded="false"><i
                                   class="icon icon-app-store"></i><span class="nav-text">Users</span></a>
                       </li>
                       <li><a class="nav-link" href="/all-products" aria-expanded="false"><i
                                   class="icon icon-chart-bar-33"></i><span class="nav-text">Products</span></a>
                       </li>
                       <li><a class="nav-link" href="/all-orders" aria-expanded="false"><i
                                   class="icon icon-world-2"></i><span class="nav-text">Orders</span></a>
                       </li>
   
                       <li><a class="nav-link" href="/all-transactions" aria-expanded="false"><i
                                   class="icon icon-plug"></i><span class="nav-text">Transactions</span></a>
                       </li>
                       <!-- <li><a class="nav-link" href="#" aria-expanded="false"><i class="icon icon-globe-2"></i><span
                                   class="nav-text">Widget</span></a></li>
                       <li><a class="nav-link" href="#" aria-expanded="false"><i
                                    class="icon icon-form"></i><span class="nav-text">Forms</span></a>
                       </li>
                       <li><a class="nav-link" href="#" aria-expanded="false"><i
                                   class="icon icon-layout-25"></i><span class="nav-text">Table</span></a>
                       </li>
                       <li><a class="nav-link" href="#" aria-expanded="false"><i
                                   class="icon icon-single-copy-06"></i><span class="nav-text">Pages</span></a>
                       </li> -->
                   </ul>
               </div>
   
   
           </div>
           <!--**********************************
               Sidebar end
           ***********************************-->
    
</template>

<script>
import { mapState } from 'vuex';
import LogoutButton from '@/components/LogoutButton.vue';

export default {
  computed: {
    ...mapState(['isAuthenticated']),
    showNav() {
      // Customize this condition based on your authentication logic
      return this.isAuthenticated;
    },
  },
  components: {
    'logout-button': LogoutButton,
  },
};
</script>